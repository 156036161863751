
import { EventBus } from '@/plugins/eventBus'
import PermissionMixin from '@/mixins/PermissionMixin.vue'
import PositionsAutocompleteMixin from '@/mixins/PositionsAutocompleteMixin.vue'
import SelectSpace from "@/components/SelectSpace.vue"
import { state } from '@/plugins/state'
import axios, { AxiosError, AxiosResponse } from 'axios'
import Vue, { VueConstructor } from 'vue'
import { DataTableHeader } from 'vuetify'

interface Jobboard {
  id: string,
  param_tracking: string,
  status: string,
  reason: string,
  external_reference?: string
}

interface CompanyJobboard {
  id: string,
  nom: string,
  url: string
}

export interface Offer {
  id: number,
  status: string,
  title: string,
  offer: {
    "@attributes": {
      offer_KeyID: string,
      status: string
    },
    jobBoards: Array<Jobboard>|Jobboard, 
    general_informations: {
      posting_date: string,
      job_reference: string,
      job_title: string
    }
    company: {
      company_name: string,
      company_url: string,
      company_id?: string, 
      user: string
    }
  }
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof PermissionMixin> & InstanceType<typeof PositionsAutocompleteMixin>>).extend({
  components: { SelectSpace },
  name: 'Multiposting',
  mixins: [
    PermissionMixin,
    PositionsAutocompleteMixin,
  ],
  data: () => ({
    loading: {
      create: false, // loading state for order creation
      republish: false, // loading state for republishing
      edit: false, // loading state for edition
      unpublish: false, // loading state for unpublishing
      delete: false, // loading state for delete modal
      offers: false, // loading state for offers list
    } as {[key: string]: boolean},
    search: "", // search offer on datatable
    selectedOffer: null as Offer|null, // view offer data
    dialog_create: false,
    dialog_view: false,
    dialog_delete: false,
    offers: [] as Array<Offer>, // offers loaded in datatables
    jobboards: [] as Array<CompanyJobboard>, // jobboards available on company
    invalidCredentials: false, // If TalentPlug replied invalid credentials
    url: null, // url use on creation iframe
    userCompanies: [] as Array<{id: string, name: string}> // user's allowed companies
  }),
  computed: {
    headers: function(): Array<DataTableHeader> {
      return [
        { text: this.$vuetify.lang.t('$vuetify.id'), value: 'id' },
        { text: this.$vuetify.lang.t('$vuetify.republishing'), value: 'republish' },
        { text: this.$vuetify.lang.t('$vuetify.offer_title'), value: 'offer.general_informations.job_title' },
        { text: this.$vuetify.lang.t('$vuetify.city'), value: 'offer.location.location_town' },
        { text: this.$vuetify.lang.t('$vuetify.client_position'), value: 'client_name' },
        { text: this.$vuetify.lang.t('$vuetify.status'), value: 'offer.@attributes.status' },
        { text: this.$vuetify.lang.t('$vuetify.published_at'), value: 'offer.general_informations.posting_date' },
        { text: this.$vuetify.lang.t('$vuetify.total_applicants'), value: 'applicants' },
        { text: this.$vuetify.lang.t('$vuetify.action'), value: 'actions', width: 360, sortable: false }
      ]
    },
    getOffersUrl: function(): string {
      let tmp = '/v1/multiposting'
      if (state.agency !== null) {
        tmp += `?space_id=${state.agency}`
      }
      return tmp
    },
    canUseMultiposting: function(): boolean {
      return this.hasPermission('multiposting') && this.invalidCredentials === false
    },
    spaceName: function(): string {
      return state.spaceName ?? ""
    },
    iframeHeigth: function(): string {
      return this.$vuetify.breakpoint.height - 61 + 'px'
    },
    offersWithoutBrouillon: function(): Array<Offer> {
      return this.offers.filter((offer: Offer) => { return offer.status !== 'Brouillon'})
    }
  },
  watch: {
    getOffersUrl() {
      this.getOffers()
    }
  },
  methods: {
    /**
     * Retrieve offer's associated company id from its company name
     * @param offer 
     */
    getCompanyId(offer: Offer) {
      let companyId = ''
      // retrieve by matching between userCompanies and offer.company.company_name
      this.userCompanies.forEach((company) => {
        // trimmed matching using localeCompare in order to get rid of accents
        // NOTE - company name came unaccented from UserCompanies API call
        if (offer.offer.company.company_name.trim().localeCompare(
          company.name.trim(),
          'fr',
          { sensitivity: 'base' }
        ) == 0) {
          companyId = company.id
        }
      })
      return companyId
    },
    /**
     * Delete an offer
     * @param offer
     */
    deleteOffer(offer: Offer) {
      this.loading.delete = true

      axios.delete('/v1/multiposting', { params: { id: offer.id }})
      .then((response: AxiosResponse) => {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.offer_delete_success'), color: 'success' }) // display success
        this.dialog_delete = false // hide modal
        this.getOffers()
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: e })
      })
      .finally(() => {
        this.loading.delete = false
      })
    },
    /**
     * Unpublish an offer
     * @param offer
     */
     unpublishOffer(offer: Offer) {
      this.loading.unpublish = true
      
      let entrepriseID = this.getCompanyId(offer)
      let xml = `<jobs>
            <job>
              <offerkey_ID>${offer.offer['@attributes'].offer_KeyID}</offerkey_ID>
              <action_name><![CDATA[Delete]]></action_name>
              <entrepriseID>${entrepriseID}</entrepriseID>
            </job>
          </jobs>`
     
      axios.get('/v1/multiposting/SendToUbipostingLite', { params: { xml: xml }})
      .then((response: AxiosResponse) => {
        let errors = Object.keys(response.data).filter((eachKey) => /error_/.test(eachKey));
        if (errors.length > 0) {
          EventBus.$emit('snackbar', { message: response.data[errors[0]], color: 'error' })
        } else {
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.offer_unpublish_success'), color: 'success' }) // display success
          this.getOffers() // reload offers
        }
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: e })
      })
      .finally(() => {
        this.loading.unpublish = false
      })
    },
    /**
     * Unpublish an offer
    * @param offer
     */
     editOffer(offer: Offer) {
      this.loading.edit = true
      
      axios.put('/v1/multiposting/' + offer.id)
      .then((response: AxiosResponse) => {
        this.url = response.data // set session URL
        this.dialog_create = true // show modal
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: e })
      })
      .finally(() => {
        this.loading.edit = false
      })
    },
    /**
     * Republish an offer and reload list
     * @param offer
     */
    republishOffer(offer: Offer) {
      this.loading.republish = true

      let entrepriseID = this.getCompanyId(offer)
      let xml = `<jobs>
            <job>
              <offerkey_ID>${offer.offer['@attributes'].offer_KeyID}</offerkey_ID>
              <action_name>republish</action_name>
              <entrepriseID>${entrepriseID}</entrepriseID>
              <force>true</force>
            </job>
          </jobs>`
      axios.get('/v1/multiposting/SendToUbipostingLite', { params: { xml }})
      .then((response: AxiosResponse) => {
        let errors = Object.keys(response.data).filter((eachKey) => /error_/.test(eachKey));
        if (errors.length > 0) {
          EventBus.$emit('snackbar', { message: response.data[errors[0]], color: 'error' })
        } else {
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.offer_republish_success'), color: 'success' })
          this.getOffers()
        }
      })
      .catch((e: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: e })
      })
      .finally(() => {
        this.loading.republish = false
      })
    },
    /**
     * Display offer details
     */
    viewOffer(offer: Offer) {
      this.selectedOffer = offer
      this.dialog_view = true
    },
    /**
     * Retrieve activate offers from the last 2 months
     */
    getOffers() {
      this.loading.offers = true
      this.offers = []
      let recalls: Promise<AxiosResponse>[] = []

      axios.get(this.getOffersUrl)
        .then((response: AxiosResponse) => {
          // store retrieved offer
          this.offers = response.data
          // prepare recalls if more pages are available
          let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(response.headers.link)
          if (match && match[1] && parseInt(match[1]) > 1) {
            for (let i = 2; i <= parseInt(match[1]); i++) {
              recalls.push(
                axios.get(this.getOffersUrl, { params: { page: i }})
              )
            }
          }
        })
        .then(() => {
          // execute recalls if any
          Promise.all(recalls)
          .then((results) => {
            results.forEach((response: AxiosResponse) => {
              // concatenate each page retrieved
              this.offers = this.offers.concat(response.data)
            })
          })
          .catch((error: AxiosError) => {
            this.invalidCredentials = true
          }).finally(() => {
            this.loading.offers = false
          })
        })
        .catch((error: AxiosError) => {
          this.invalidCredentials = true
        })
    },
    /**
     * Create an offer from position
     */
    createOffer() {
      if (!this.selectedPositions) {
        return
      }
      
      this.loading.create = true
      axios
        .post(`/v1/multiposting/${this.selectedPositions}`)
        .then((response: AxiosResponse) => {
          this.url = response.data
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
        .finally(() => {
          this.loading.create = false
        })
    },
    closeCreateOfferModal() {
      this.dialog_create = false // hide modal
      this.url = null // reset iframe url
      this.selectedPositions = null // reset selected position
      this.getOffers() // refresh offers
    },
    getJobboardUrl(id: string) {
      let url = null
      this.jobboards.forEach((eachJobboard: CompanyJobboard) => {
        if (eachJobboard.id == id && typeof eachJobboard.url === 'string') {
          url = eachJobboard.url
        }
      })
      return url
    }
  },
  mounted() {
    // retrieve user companies
    axios.get('/v1/multiposting/UserCompanies')
      .then((response: AxiosResponse) => {
        // affect available companies
        if (Array.isArray(response.data.company) === false) { // only 1 company
          this.userCompanies = [response.data.company]
        } else {
          this.userCompanies = response.data.company
        }
      })
      .catch((error: AxiosError) => {
        this.invalidCredentials = true
      })
    // retrieve company jobboards
    axios.get('/v1/multiposting/ListJobBoards')
      .then((response: AxiosResponse) => {
        // affect available jobboards
        if (Array.isArray(response.data.jobboard) === false) { // only 1 jobboard
          this.jobboards = [response.data.jobboard]
        } else {
          this.jobboards = response.data.jobboard
        }
      })
      .catch((error: AxiosError) => {
        this.invalidCredentials = true
      })
    // get offers
    this.getOffers()
  }
})
